import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import BigNumber from 'bignumber.js'
import { intersection } from 'lodash'
import { finalize } from 'rxjs'
import { File as IFile } from 'src/app/common/api-interfaces'
import { Transaction } from 'src/app/common/models/accounting/transaction.model'
import { calculateCreditableAmount } from 'src/app/common/transactions.mixins'

interface ThunesCollectedData {
    quoteId: string
    purpose: string
    documentNumber: string
    additionalInformation: string
    bsbNumber: string
    phone: string
    email: string

    documents: Record<string, string>
    identifier: Record<string, any>
    receiver: Record<string, any>
}

@Component({
    selector: 'thunes-payout',
    templateUrl: 'thunes-payout.component.html',
})
export class ThunesPayoutComponent implements OnInit {
    @Input()
    public transaction: Transaction
    @Input()
    public collectedData: ThunesCollectedData
    @Output()
    public collectedDataChanges = new EventEmitter<ThunesCollectedData>()

    public payer: Payer | null
    public info: TransactionType
    public quotation: Quotation
    public validations: { receiver: Record<string, string>; sender: Record<string, string> } = {
        receiver: {},
        sender: {},
    }
    public collectCreditIdentifiers: string[] = []
    public amountToThunes: number
    public creditableAmount: number

    public forManualInputCreditIdentifiers = [
        'ifs_code',
        'sort_code',
        'aba_routing_number',
        'bsb_number',
        'branch_number',
        'routing_code',
        'entity_tt_id',
        'account_type',
        'account_number',
        'msisdn',
        'email',
        'card_number',
    ]
    public purposes = [
        'COMPUTER_SERVICES',
        'FAMILY_SUPPORT',
        'EDUCATION',
        'GIFT_AND_DONATION',
        'MEDICAL_TREATMENT',
        'MAINTENANCE_EXPENSES',
        'TRAVEL',
        'SMALL_VALUE_REMITTANCE',
        'LIBERALIZED_REMITTANCE',
        'CONSTRUCTION_EXPENSES',
        'HOTEL_ACCOMMODATION',
        'ADVERTISING_EXPENSES',
        'ADVISORY_FEES',
        'BUSINESS_INSURANCE',
        'INSURANCE_CLAIMS',
        'DELIVERY_FEES',
        'EXPORTED_GOODS',
        'SERVICE_CHARGES',
        'LOAN_PAYMENT',
        'OFFICE_EXPENSES',
        'PROPERTY_PURCHASE',
        'PROPERTY_RENTAL',
        'ROYALTY_FEES',
        'SHARES_INVESTMENT',
        'FUND_INVESTMENT',
        'TAX_PAYMENT',
        'TRANSPORTATION_FEES',
        'UTILITY_BILLS',
        'PERSONAL_TRANSFER',
        'SALARY_PAYMENT',
        'REWARD_PAYMENT',
        'INFLUENCER_PAYMENT',
        'OTHER_FEES',
        'OTHER',
    ]
    public idTypes = [
        'PASSPORT',
        'NATIONAL_ID',
        'DRIVING_LICENSE',
        'SOCIAL_SECURITY',
        'TAX_ID',
        'SENIOR_CITIZEN_ID',
        'BIRTH_CERTIFICATE',
        'VILLAGE_ELDER_ID',
        'RESIDENT_CARD',
        'ALIEN_REGISTRATION',
        'PAN_CARD',
        'VOTERS_ID',
        'HEALTH_CARD',
        'EMPLOYER_ID',
        'OTHER',
    ]
    public accountTypes = ['CHECKING', 'SAVINGS', 'DEPOSIT', 'OTHERS']
    public genders = ['MALE', 'FEMALE']

    public transactionFiles: IFile[] = []

    public isFetchingPayer = false
    public isInit = true

    constructor(private http: HttpClient) {}

    public ngOnInit(): void {
        this.creditableAmount = parseFloat(calculateCreditableAmount(this.transaction))
        this.amountToThunes = new BigNumber(calculateCreditableAmount(this.transaction)).times(0.9845).toNumber()
    }

    public fetchInformation(): void {
        if (!this.amountToThunes) {
            return
        }

        this.isInit = false
        this.isFetchingPayer = true

        this.http
            .post<ValidateResponse | null>('/admin/thunes/validate', {
                transactionId: this.transaction.id,
                amountToThunes: this.amountToThunes + '',
            })
            .pipe(
                finalize(() => {
                    this.isFetchingPayer = false
                })
            )
            .subscribe(response => {
                this.payer = response?.payer || null

                if (response?.payer) {
                    this.info =
                        this.transaction.beneficiary?.userType === 'personal'
                            ? response.payer.transaction_types.B2C
                            : response.payer.transaction_types.B2B

                    if (this.info.purpose_of_remittance_values_accepted.length > 0) {
                        this.purposes = this.info.purpose_of_remittance_values_accepted
                    }

                    this.collectCreditIdentifiers = intersection(
                        this.forManualInputCreditIdentifiers,
                        this.info.credit_party_identifiers_accepted[0]
                    )
                }

                if (response?.quotation) {
                    this.quotation = response.quotation
                    this.collectedData.quoteId = response.quotation.id + ''
                }

                if (response?.validations) {
                    this.validations = response.validations
                }
            })

        this.http.get<IFile[]>(`/admin/transactions/${this.transaction.id}/files`).subscribe(files => {
            this.transactionFiles = files
        })
    }
}

interface TransactionType {
    minimum_transaction_amount: number
    maximum_transaction_amount: number | null
    credit_party_identifiers_accepted: [string[]]
    required_sending_entity_fields: [string[]]
    required_receiving_entity_fields: [string[]]
    required_documents: [string[]]
    purpose_of_remittance_values_accepted: string[]
}

interface Payer {
    id: number
    name: string
    precision: number
    increment: number
    currency: string
    country_iso_code: string
    service: {
        id: number
        name: string
    }
    transaction_types: Record<'C2C' | 'C2B' | 'B2C' | 'B2B', TransactionType>
}

interface Quotation {
    id: number
    fee: {
        currency: string
        amount: number
    }
    source: {
        country_iso_code: string
        currency: string
        amount: number
    }
    destination: {
        currency: string
        amount: number
    }
}

interface ValidateResponse {
    payer: Payer
    quotation: Quotation
    validations: {
        sender: Record<string, string>
        receiver: Record<string, string>
    }
}
